import AOS from 'aos';

function initPage(container) {
	// init tooltips
	// --------------------
	container.find('[data-bs-toggle="tooltip"]').tooltip();

	// init popovers
	// --------------------
	container.find('[data-bs-toggle="popover"]').popover();

	// init form
	// --------------------
	if (container.find('*[data-book-meeting-form]').length) {
		container.find('[data-book-meeting-form]').bookMeeting();
	}

	// init animate on scroll
	// --------------------
	AOS.init({
		disable: 'mobile',
		duration: 400,
		easing: 'ease',
		once: true
	});

	// external links
	container.find('a[href^="http://"], a[href^="https://"]')
		.not('a[href*="plecto.com"]')
		.not('a[href*="herokuapp.com"]')
		.not('a[href*="localhost"]')
		.attr('target', '_blank')
		.attr('rel', 'noopener noreferrer');
}


function initNavbar() {
	// display value of selected item in nav item with dropdown
	$('.dropdown-menu-mobile a').on('click', function() {
		$('.dropdown-toggle-mobile').html($(this).html());
	});

	// Add shadow while scrolling inner mobile navigation
	var $navbar = $('.navbar-nav');
	var $navItem = $('.nav-item');

	$navItem.on('show.bs.dropdown', function() {
		$navbar.scroll(function() {
			var position = $navbar.scrollTop();
			if (position > 0) {
				$navbar.addClass("navbar-nav-expanded");
			} else  {
				$navbar.removeClass("navbar-nav-expanded");
			}
		});
	});

	$navItem.on('hide.bs.dropdown', function() {
		$navbar.off('scroll');
	});

	const $mainNav = $('#main-nav-list');
	const $body = $('body');
	const $collapsedLogo = $('.collapsed-logo');
	const $expandedLogo = $('.expanded-logo');

	$mainNav.on('shown.bs.collapse', function () {
		$body.addClass('mobile-nav-show');
		$collapsedLogo.addClass('d-none');
		$expandedLogo.removeClass('d-none');
	})

	$mainNav.on('hidden.bs.collapse', function () {
		$body.removeClass('mobile-nav-show');
		$collapsedLogo.removeClass('d-none');
		$expandedLogo.addClass('d-none');
	})

	// alight dropdown-menu to the right on overflow
 	$('.main-nav .dropdown').on('mouseenter', function() {
		var elm = $(this);
		elm.removeClass('fill-width');
		var $dropdown = elm.find('.dropdown-menu');
		var containerWidth = $(".navbar-wrapper").width();
		var width = $dropdown.width();
		var position = $dropdown.position();

		if (containerWidth - (width + position.left) < 0) {
			elm.addClass('fill-width');
		}
	}); 

	// Prevent clicks from opening dropdowns on desktop
	$('.navbar-nav .dropdown-toggle').on('click', function(e) {
		if (window.innerWidth >= 1200) { // xl breakpoint
			e.preventDefault();
			const href = $(this).attr('href');
			if (href && href !== '#') {
				window.location.href = href;
			}
			// Close any open dropdowns
			$('.dropdown-menu').removeClass('show');
			$('.dropdown').removeClass('show');
		}
	});
}

function toggleClassOnScroll(element) {
	var lastScrollTop = 0;
	var viewportHeight = $(window).height();
	var navbarHeight = element.innerHeight();
	var hasScrolled = function() {
		var position = $(window).scrollTop();
		if (position > lastScrollTop) {
			// Scroll Down
			if (position > navbarHeight) {
				element.removeClass('nav-shown').addClass('nav-sticky');
			}
			if (position > viewportHeight / 2) {
				element.addClass('nav-hidden');
			}
		} else {
			// Scroll Up
			if (position + viewportHeight < $(document).height() && position > viewportHeight) {
				element.removeClass('nav-hidden').addClass('nav-shown');
			} else {
				if (position < viewportHeight) {
					element.removeClass('nav-shown').addClass('nav-hidden');
				}
				if (position < navbarHeight) {
					element.removeClass('nav-sticky nav-hidden');
				}
			}
		}

		lastScrollTop = position;
	};

	$(window).scroll(function(){
		hasScrolled();
	});
}

// init plugins after body load
$(function () {
	initPage($('body'));
	initNavbar();
	toggleClassOnScroll($('#js_navbar'));
});

// CSRF protection

function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}
$.ajaxSetup({
    beforeSend: function(xhr, settings) {
        if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
            xhr.setRequestHeader("X-CSRFToken", getCookie('csrftoken'));
        }
    }
});

