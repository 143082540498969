(function ($) {
	const Youtube = {
		init: function () {
			// Load the YouTube IFrame API asynchronously
			if (!window.onYouTubeIframeAPIReady) {
				window.onYouTubeIframeAPIReady = () => {
					const event = new Event('youtube-api-ready');
					document.dispatchEvent(event);
				};
			}

			const scriptUrl = 'https://www.youtube.com/iframe_api';
			if (!$(`script[src="${scriptUrl}"]`).length) {
				$('<script>', { src: scriptUrl }).insertBefore($('script').first());
			}
		},

		createModal: function () {
			// Add a Bootstrap modal dynamically if not already present
			if (!$('#youtubeVideoModal').length) {
				const modalHtml = `
				<div class="modal fade" id="youtubeVideoModal" tabindex="-1" aria-hidden="true">
					<div class="modal-dialog modal-dialog-centered modal-lg">
						<div class="modal-content">
							<div class="ratio ratio-16x9">
								<div id="youtubePlayerContainer" class="ratio ratio-16x9"></div>
							</div>
						</div>
					</div>
				</div>`;
				$('body').append(modalHtml);
			}
		},

		loadPlayer: function (videoId, containerId, onReadyCallback) {
			return new YT.Player(containerId, {
				height: '100%',
				width: '100%',
				videoId,
				events: {
					onReady: (event) => onReadyCallback(event),
				},
			});
		},

		initModalTrigger: function (trigger) {
			const videoId = trigger.data('youtube-videoid');
			let player;

			const $modal = $('#youtubeVideoModal');

			trigger.on('click', function (e) {
				e.preventDefault();
				$modal.modal('show');
				if (!player) {
					player = Youtube.loadPlayer(videoId, 'youtubePlayerContainer', (event) => event.target.playVideo());
				} else {
					player.loadVideoById(videoId);
				}
			});


			// Prevent clicks on the modal content from closing the modal
			$('#youtubePlayerContainer').on('click', function (e) {
				e.stopPropagation();
			});

			// Close modal only when clicking on the overlay
			$modal.on('click', function (e) {
				const $target = $(e.target);
				if ($target.is('#youtubeVideoModal')) {
					$modal.modal('hide');
					if (player) player.stopVideo();
				}
			});

			$modal.on('hidden.bs.modal', function () {
				if (player) player.stopVideo();
			});
		},

		initEmbedPlayer: function (trigger) {
			const videoId = trigger.data('youtube-videoid');
			const containerId = `video-embed-player-${Math.random().toString(36).slice(2, 9)}`;

			// Replace the trigger with the YouTube player container
			const $videoContainer = $('<div>', {id: containerId, class: 'ratio ratio-16x9'});
			trigger.replaceWith($videoContainer);

			// Load the player
			Youtube.loadPlayer(videoId, containerId, (event) => {
				$(`#${containerId}`).on('click', function (e) {
					e.preventDefault();
					event.target.playVideo();
				});
			});
		},
	};

	$(document).ready(function () {
		Youtube.init();
		Youtube.createModal();

		// Listen for the API ready event
		document.addEventListener('youtube-api-ready', () => {
			// Initialize modal triggers
			$('[data-youtube-modal-trigger]').each(function () {
				Youtube.initModalTrigger($(this));
			});

			// Initialize embed players
			$('[data-youtube-embed-trigger]').each(function () {
				Youtube.initEmbedPlayer($(this));
			});
		});
	});
})(jQuery);
