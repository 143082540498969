(function ($) {
    const VimeoPlayer = {
        init: function () {
			const scriptUrl = 'https://player.vimeo.com/api/player.js';
			if (!$(`script[src="${scriptUrl}"]`).length) {
				const script = document.createElement('script');
				script.src = scriptUrl;
				script.onload = function () {
					console.log('Vimeo Player API loaded successfully.');
					$(document).trigger('vimeo-api-ready');
				};
				script.onerror = function () {
					console.error('Failed to load Vimeo Player API.');
				};
				document.head.appendChild(script);
			} else {
				$(document).trigger('vimeo-api-ready');
			}
        },

        createModal: function () {
            // Add a Bootstrap modal dynamically if not already present
            if (!$('#vimeoVideoModal').length) {
                const modalHtml = `
                <div class="modal fade" id="vimeoVideoModal" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg">
						<div class="modal-content">
							<div class="ratio ratio-16x9">
								<div id="vimeoPlayerContainer" class="ratio ratio-16x9"></div>
							</div>
						</div>
                    </div>
                </div>`;
                $('body').append(modalHtml);
            }
        },

        loadPlayer: function (videoId, containerId) {
            const options = {
                id: videoId,
                width: '100%',
                autoplay: false,
                transparent: false 
            };
            
            const player = new Vimeo.Player(containerId, options);
            
            const container = document.getElementById(containerId);
            if (container) {
                container.style.backgroundColor = '#03215B';
            }
            
            return player;
        },

        initModalTrigger: function (trigger) {
            const videoId = trigger.data('vimeo-videoid');
            let player;

			const $modal = $('#vimeoVideoModal');

            trigger.on('click', function (e) {
                e.preventDefault();
                $modal.modal('show');

                if (!player) {
                    player = VimeoPlayer.loadPlayer(videoId, 'vimeoPlayerContainer');
                    player.play();
                } else {
                    player.loadVideo(videoId).then(() => player.play());
                }
            });

			// Prevent clicks on the modal content from closing the modal
			$('#vimeoPlayerContainer').on('click', function (e) {
				e.stopPropagation();
			});

			// Close modal only when clicking on the overlay
			$modal.on('click', function (e) {
				const $target = $(e.target);
				if ($target.is('#vimeoVideoModal')) {
					$modal.modal('hide');
					if (player) player.pause();
				}
			});

            $modal.on('hidden.bs.modal', function () {
                if (player) player.pause();
            });
        },

        initEmbedPlayer: function (trigger) {
            const videoId = trigger.data('vimeo-videoid');
            const containerId = `video-embed-player-${Math.random().toString(36).slice(2, 9)}`;

            // Replace the trigger with the Vimeo player container
            const $videoContainer = $('<div>', { id: containerId, class: 'ratio ratio-16x9' });
            trigger.replaceWith($videoContainer);

            // Create the player
            return new VimeoPlayer.loadPlayer(videoId, containerId);
        },
    };

    $(document).ready(function () {
        VimeoPlayer.init();
        VimeoPlayer.createModal();

		$(this).on('vimeo-api-ready', function () {
			$('[data-vimeo-modal-trigger]').each(function () {
				VimeoPlayer.initModalTrigger($(this));
			});

			$('[data-vimeo-embed-trigger]').each(function () {
				VimeoPlayer.initEmbedPlayer($(this));
			});
		});
    });
})(jQuery);
