import { Navigation, Pagination } from 'swiper/modules';
import Swiper from 'swiper';

$(function () {
	const swiperSelector = '*[data-swiper]';
	const $swiperContainers = $(swiperSelector);

	Swiper.use([Navigation, Pagination]);

	$swiperContainers.each(function () {
		const $swiperContainer = $(this);
		const $swiperSlides = $swiperContainer.find('*[data-swiper-slide]');

		let options = {
			modules: [Pagination],
			slidesPerView: 1,
			speed: 600,
			centeredSlides: true,
			spaceBetween: 75,
			loop: false,
			pagination: {
				type: 'bullets',
				el: $swiperContainer.find('.swiper-pagination')[0],
				clickable: true,
			},
			navigation: {
				nextEl: $swiperContainer.find('.swiper-button-next')[0],
				prevEl: $swiperContainer.find('.swiper-button-prev')[0],
			}
		};

		const isDisabled = $swiperSlides.length === 1;
		if (isDisabled) {
			$.extend(options, {
				spaceBetween: 0,
				watchOverflow: true,
			});
		}

		// Initialize Swiper for the current container
		new Swiper($swiperContainer.find('.swiper')[0], options);
	});
});
